const state = {
    supports: {
        data: [],
        meta: {
            total: 0,
            page: 0,
            last_page: 0
        }
    }
}

export default state